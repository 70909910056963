import React from 'react'
import { graphql } from 'gatsby'
import LocaleContext from '../contexts/LocaleContext'
import Layout from '../components/Layout'
import PostsList from '../components/PostsList'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Footer from '../components/Footer'
import Menu from '../components/Menu'

export const query = graphql`
  query($locale: String!, $slug: String!) {
    allDatoCmsPost(
      filter: {
        locale: { eq: $locale }
        tags: { elemMatch: { slug: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
    datoCmsNewMenu {
      ...Menu
    }
    datoCmsFooter {
      ...Footer
    }
  }
`

function BlogPosts({
  data: { allDatoCmsPost, datoCmsFooter, datoCmsNewMenu },
  pageContext,
}) {
  return (
    <LocaleContext.Provider value={pageContext.locale}>
      <Layout locale={pageContext.locale}>
      <Menu
          transparent={false}
          menu={datoCmsNewMenu}
          socialMedia={datoCmsFooter.socialMedia}
        />
        <div>
          <HelmetDatoCms seo={allDatoCmsPost.seoMetaTags} />

          <section className="container center-width">
            <div className="prose">
              <h1>{pageContext.tag}</h1>
              {!allDatoCmsPost.edges[0] && (
                <p>It seems there are currently no posts available.</p>
              )}
              <PostsList posts={allDatoCmsPost.edges} />
            </div>
          </section>

        </div>
        <Footer footer={datoCmsFooter} />
      </Layout>
    </LocaleContext.Provider>
  )
}

export default BlogPosts
